.reservation-modal {
  top: 5px;
  width: 500px;

  &.MuiModal-root {
    left: unset !important;
    .MuiPaper-root {
      max-height: 100%;
      height: calc(100vh - 10px);
      margin: 0 !important;
      width: 100% !important;
      &.MuiAutocomplete-paper {
        height: auto;
      }
    }
  }

  &.edit {
    .dialog-header {
      padding-top: 20px;
      margin-left: -50px;
      margin-right: -50px;

      &.confirmed {
        background-color: #F1FFFE;
        color: #17B183;

        svg {
          color: #AAD9D6;
        }
      }

      &.cancelled {
        background-color: #FFFAFA;
        color: #E81515;

        svg {
          color: #FAC2C2;
        }
      }

      &.rejected {
        background-color: #FFF9F0;
        color: #DA6D00;

        svg {
          color: #F9CD8B;
        }
      }

      &.finished {
        background: #F7FAFF;
        color: #155CE7;

        svg {
          color: #B4C2DF;
        }
      }

      &.waiting_for_partner_approval, &.waiting_for_client_approval {
        background: #FFFDF5;
        color: #D19A0B;

        svg {
          color: #E3CB79;
        }
      }

      .status {
        margin-top: 10px;
        height: 46px;
        border-radius: 9px;
        .icon-collapse {
          transition: transform .2s ease-in-out;
        }
        &.show {
          .icon-collapse {
            transform: rotate(180deg);
          }
        }
        .nav-link {
          width: 100%;
          text-align: center;
          height: 100%;
          color: #17B183;
          font-size: 14px;
          border-radius: 9px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          &.left {
            justify-content: left;
            div.text {
              width:100%;
              text-align: center;
            }
          }
          .bg-icon {
            padding: 7px;
            border-radius: 26px;
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &.confirmed {
            color: #17B183;
            background-color: #D1FFF1;
            svg {
              color: #17B183;
            }
            .bg-icon {
              background-color: #B9F3E2;
            }
          }
          &.cancelled {
            background-color: #FFEBEB;
            color: #E81515;
            svg {
              color: #E81515;
            }
            .bg-icon {
              background-color: #FFE1E1;
            }
          }
          &.finished {
            background-color: #DCE8FF;
            color: #155CE7;
            svg {
              color: #155CE7;
            }
            .bg-icon {
              background-color: #CDDEFF;
            }
          }
          &.waiting_for_partner_approval, &.waiting_for_client_approval {
            background: #FFF3CD;
            color: #D19A0B;
            svg {
              color: #D19A0B;
            }
            .bg-icon {
              background: #F9E9B1;
            }
          }
          &.rejected {
            background: #FFE7C2;
            color: #DA6D00;
            svg {
              color: #DA6D00;
            }
            .bg-icon {
              background: #FFD696;
            }
          }
        }

        .dropdown-menu-lg {
          width: 100%;
          border: none;
          box-shadow: 0px 20px 51px rgba(85, 101, 171, 0.06), 0px 0.375647px 6.01034px rgba(85, 101, 171, 0.06);
          border-radius: 0px 0px 10px 10px;
          .menu-item {
            padding: 8px;
            button {
              height: 100%;
              width: 100%;
              border-radius: 5px;
              text-align: center;
              font-size: 12px;
              padding: 8px;
              cursor: pointer;
              &.confirmed {
                color: #17B183;
                background-color: #E2FFF6;
              }
              &.cancelled {
                background-color: #FFEBEB;
                color: #E81515;
              }
            }
          }
        }
      }
    }
  }

  .create-header {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
  }

  .edit-header {
    font-size: 12px;
    .info {
      text-align: center;
    }
  }

  .form-field {
    margin-bottom: 19px;
  }

  .add-client-btn {
    background-color: white !important;
    color: #1D6AFF !important;
    padding-bottom: 2px;
    padding-top: 2px;
    height: 12px;
    font-weight: normal;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .client-select, .service-select {
    margin-bottom: 0;
  }

  .service-info {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #002678;
    line-height: 20px;
  }
}