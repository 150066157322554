@import url('https://fonts.googleapis.com/css?family=Poppins');
@import "node_modules/bootstrap/scss/bootstrap";
@import '../../core/scss/main.scss';
@import "sidebar";
@import "dashboard";
@import "calendarfilterpanel";
@import 'auth';
@import 'form';
@import 'creator';
@import 'openinghours';
@import 'facilities';
@import 'objectpage';
@import 'facilitypage';
@import 'galleryeditor';
@import 'calendar';
@import 'reservationmodal';

body {
  font-family: Poppins, serif;
  margin: 0;
  color: white;
  background: #FCFDFF;

  .main {
    @import 'navbar';
    overflow-y: hidden;
    display: flex;
    min-height: 100vh;
    min-width: 0;
    flex-direction: row;
    width: 100%;

    .content {
      flex: 1 1;
    }

  }

  .wrapper {
    min-height: 100vh;
  }

}

.wrapper {
  display: flex;
}

button {
  outline: none;
}

.d-flex-justify {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #9cc2f5;
}

.maxheight {
  form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  height: 100%;
}

.list-page {
  margin: 33px 27px;

  .button-panel {
    button {
      margin-right: 22px;
    }
  }

  .object-list {
    margin-top: 10px;

    .object-list-item {
      padding: 40px 16px 16px;

      button {
        margin-top: 10px;
        background-color: #E3EFFF !important;
        color: #1D6AFF !important;
      }

      .card-footer {
        padding-left: 0;
        padding-right: 0;
      }
    }

    table {
      th {
        border-top: none;
      }

      button {
        background-color: #E3EFFF !important;
        color: #1D6AFF !important;
      }
    }

  }

}

table.data-list {
  tr {
    height: 44px;
  }
}

.table, .data-list {
  color: #00359B;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  width: 100%;

  tr {
    &:nth-child(odd) {
      background: #F9FBFF;
    }
  }
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #bb86fc;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: white;
    font-size: 20px;
  }
}

.emaillink {
  text-decoration: none;
  color: #00359B;

  &:hover {
    text-decoration: none;
    color: #00359B;
  }
}

.partner-availability {
  width: fit-content;
  padding: 14px;
  height: 38px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;

  svg {
    margin-right: 10px;
  }

  &.active {
    background-color: #EDFDFC;
    color: #6BF2D9;
  }

  &:not(.active) {
    background-color: #FAF0ED;
    color: #F9A7A7;
  }
}

.bold {
  font-weight: bold;
}

.invite-partner-btn {
  width: 100%;
  height: 55px !important;
}

.borderedcontainer {
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;

  &.color1 {
    border-color: #DFEAFF;
  }

  &.color2 {
    border-color: #BFD5FF;
  }

  & > .form-field {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.page-header-text {
  color: #003CB0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  text-align: center;
  padding: 17px;
}

.permissions-table {
  margin-top: 0;

  .form-field.switch {
    margin: 0;
  }

  thead {
    tr {
      th:first-child {
        width: 250px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0;

        span.react-loading-skeleton {
          margin: 10px;
        }
      }

      td:first-child {
        width: 250px;
      }
    }
  }
}


.dropdown-menu-end {
  right: 0;
  left: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-dialog.resource-type {
  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #1D6AFF;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;

    svg {
      margin-right: 10px;
    }
  }

  .list {
    display: flex;
    flex-direction: column;

    .item {
      padding-top: 10px;
      padding-left: 20px;
      padding-bottom: 10px;
      height: 58px;

      input {
        background-color: #F8FAFF;
        height: 39px;
        border: 0.2px solid #1D6AFF;
        box-shadow: inset -1px 1px 4px rgba(0, 59, 171, 0.38);
        border-radius: 5px;
        padding-left: 10px;
        color: #00359B;
        font-size: 14px;
        line-height: 16px;

        &:focus-visible {

        }
      }

      &:nth-child(odd) {
        background-color: #F1F5FC;
      }

      display: flex;
      align-items: center;

      .title {
        color: #00359B;
        flex: 1;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        font-style: normal;
        display: flex;
        align-items: center;

        span {

        }
      }

      svg {
        color: #1D6AFF;
        margin-left: 10px;
        margin-right: 10px;

        &:hover {
          cursor: pointer;
        }
      }

      .edit, .remove {
        padding: 5px;

        button {
          height: 32px !important;
          width: 20px;
        }
      }
    }
  }
}

table {
  --bs-table-bg: transparent !important;
  --bs-table-accent-bg: transparent !important;
  --bs-table-striped-bg: transparent !important;
  --bs-table-active-bg: transparent !important;
  --bs-table-hover-color: transparent !important;
  --bs-table-hover-bg: transparent !important;
  border-collapse: unset;
}

.reservation-condition-table {
  border: 0.4px solid #C0D6FF;
  height: 100%;

  svg {
    height: 30px;
    margin-left: 5px;
  }

  .remove {
    padding: 0;
    color: #E7EFFE;
  }

  .add {
    color: #D2E2FF;
    padding: 0;
  }

  tr:first-child {
    background-color: white;
  }

  tr:nth-child(2) {
    background: #F9FBFF;
  }

  tr:nth-child(3) {
    background: #F9FBFF;
  }

  tr:nth-child(4) {
    background-color: white;
  }

  .form-field {
    margin: 0;
  }

  tr {
    td {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    td.header-column {
      color: #00359B;
      font-size: 14px;
      padding: 20px;
      width: 250px;
    }

    td.facility-type-column {
      display: flex;
      flex-direction: row;
      min-width: 200px;
      justify-content: center;
      align-items: center;
      padding-right: 30px;

      .form-field {
        width: 100%;
        margin: 0;
      }
    }

    td:nth-child(3) {
    }

    td:nth-child(4) {
    }
  }
}

svg {
  &.remove {
    color: #EFF5FF;
    cursor: pointer;
  }

  &.add {
    color: #D2E2FF;
    cursor: pointer;
  }
}

.counter-input {
  display: flex;
  flex-direction: row;
  align-items: center;

  .MuiFormControl-root {
    width: 45px;
  }
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #DEE7F7;
  width: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #DEE7F7;
}

.fc-day-today {
  background-color: #F9FBFF !important;
}

.fc-timegrid-event-harness {
  transition-property: z-index;
  transition-delay: 0s;

  &:hover {
    z-index: 9999 !important;
    transition-delay: .7s;
  }

  .fc-timegrid-event {
    transition-duration: 0s;
    transition-delay: 0s;
    min-width: 100%;
    z-index: 0;
    border-radius: 4px !important;
    padding: 6px;

    &:hover {
      transition-property: all;
      transition-duration: .2s;
      transition-delay: .7s;
      min-width: 150px;
      z-index: 9999 !important;
    }
  }
}

.fc-timegrid-slots {
  tr:hover {
    background-color: lightblue;

    .fc-timegrid-slot-lane {
      background-color: lightblue;
    }
  }
}

.price-variant-modal {
  .time-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    .form-field {
      flex-grow: 1;
      margin: 0;
    }
  }
}

.variable-price-panel {
  .edit > button {
    color: #1D6AFF !important;
    svg {
      margin-left: 9px;
    }
  }
  .remove > button {
    color: red !important;
    svg {
      margin-left: 10px;
    }
  }
  tr {
    td {
      padding: 10px;
    }
  }
  th {
    text-align: center;
  }
  ul {
    margin-bottom: 0;
  }
  div {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.subdomain-field {
  color: #0038B0;
  display: flex;
  .form-field {
    flex: 1;
  }
  div.domain {
    padding-top: 20px;
  }
}