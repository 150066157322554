.sidebar-search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 55px 14px 66px;

  input {
    width: 100%;
    border-radius: 24px;
    border: none;
    min-height: 38px;
    background-color: #1D6AFF;
    padding-left: 25px;
    outline: none;
    color: #F7F9FF;
    font-size: 14px;
    font-weight: 500;
  }

  svg {
    position: absolute;
    right: 25px;
    cursor: pointer;
  }

  input:focus {
    outline: none;
  }

  input::placeholder {
    color: #F7F9FF;
  }
}